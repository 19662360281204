// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function editGeneral(id) {
  return "/dashboard/edit/general/" + ID.toString(id);
}

function editLocation(id) {
  return "/dashboard/edit/location/" + ID.toString(id);
}

function editAssets(id) {
  return "/dashboard/edit/assets/" + ID.toString(id);
}

var Dashboard = {
  index: "/dashboard/products",
  $$new: "/dashboard/add",
  editGeneral: editGeneral,
  editLocation: editLocation,
  editAssets: editAssets
};

export {
  Dashboard ,
}
/* ID Not a pure module */
