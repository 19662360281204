// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../libs/ID.res.js";
import * as Api from "../../api/Api.res.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Date from "../../libs/Date.res.js";
import * as Link from "../../styleguide/components/Link/Link.res.js";
import * as Role from "../../models/Role.res.js";
import * as Hooks from "../../libs/Hooks.res.js";
import * as Money from "../../libs/Money.res.js";
import * as React from "react";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../styleguide/forms/Toggle/Toggle.res.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Control from "../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Spinner from "../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Container from "../../styleguide/components/Container/Container.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../styleguide/forms/SearchField/SearchField.res.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as AdminTopNavbar from "../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Routes_Product from "../../routes/common/Routes_Product.res.js";
import * as ProviderAsFilter from "../../models/ProviderAsFilter.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductsScss from "./DashboardProducts.scss";
import * as DashboardProductsActionBar from "./DashboardProductsActionBar.res.js";
import * as DashboardProductStatusBadge from "./DashboardProductStatusBadge.res.js";

var css = DashboardProductsScss;

var cmp = Caml_obj.compare;

var ProductStatusId = Belt_Id.MakeComparable({
      cmp: cmp
    });

var initialState_sortBy = {
  NAME: "Name",
  VAL: "Asc"
};

var initialState_selection = ID.$$Set.make();

var initialState_providerFilter = ID.$$Set.make();

var initialState_statusFilter = Belt_Set.fromArray([
      "Active",
      "Draft",
      "Disabled"
    ], ProductStatusId);

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingProducts",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  selection: initialState_selection,
  providerFilter: initialState_providerFilter,
  statusFilter: initialState_statusFilter,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false
};

function DashboardProducts$DashboardProducts(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var providers = props.providers;
  var container = React.useRef(null);
  var searchProducts = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProducts" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api.fetchDashboardProducts(state.currentPage, tmp, state.sortBy, Belt_Set.toList(state.statusFilter), Belt_Set.toList(state.providerFilter)), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProductsFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProducts",
                                            subModulePath: {
                                              hd: "DashboardProducts",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardProducts.DashboardProducts.make"
                                          }, "DashboardProducts::FetchProducts::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProductsFetch");
                                    }));
                            })
                        };
              case "FailProductsFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedProductsFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          }
                        };
              case "SortByName" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var match$1 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$1 === "object" && match$1.NAME === "Name" && match$1.VAL !== "Desc" ? ({
                                  NAME: "Name",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Name",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "SortByPrice" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var match$3 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$3 === "object" && match$3.NAME === "Price" && match$3.VAL !== "Desc" ? ({
                                  NAME: "Price",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Price",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "SortByStartDate" :
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var match$5 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$5 === "object" && match$5.NAME === "StartDate" && match$5.VAL !== "Desc" ? ({
                                  NAME: "StartDate",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "StartDate",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "SortByEndDate" :
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var match$7 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$7 === "object" && match$7.NAME === "EndDate" && match$7.VAL !== "Desc" ? ({
                                  NAME: "EndDate",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "EndDate",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "SortByProviderName" :
                  var match$8 = state.status;
                  if (typeof match$8 !== "object" && match$8 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var match$9 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$9 === "object" && match$9.NAME === "ProviderName" && match$9.VAL !== "Desc" ? ({
                                  NAME: "ProviderName",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "ProviderName",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "ToggleSelectionOfAllItems" :
                  var match$10 = state.status;
                  var match$11 = state.batchUpdate;
                  if (typeof match$10 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$11 !== undefined) {
                    return "NoUpdate";
                  }
                  var products = match$10._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: products.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(products, (function (product) {
                                          return product.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          }
                        };
              case "DeselectAll" :
                  var match$12 = state.status;
                  var match$13 = state.batchUpdate;
                  if (typeof match$12 !== "object" || match$13 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            }
                          };
                  }
              case "ActivateInBatch" :
                  var products$1 = state.status;
                  if (typeof products$1 !== "object") {
                    return "NoUpdate";
                  }
                  var products$2 = products$1._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Activating",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var productIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (product) {
                                                          return Caml_obj.equal(id, product.id);
                                                        }), __x);
                                          })(products$2);
                                      if (match !== undefined && match.status === "Disabled") {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.activateProductsInBatch(productIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedActivateInBatch",
                                                    _0: productIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProducts",
                                            subModulePath: {
                                              hd: "DashboardProducts",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardProducts.DashboardProducts.make"
                                          }, "DashboardProducts" + "::ActivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "DeactivateInBatch" :
                  var products$3 = state.status;
                  if (typeof products$3 !== "object") {
                    return "NoUpdate";
                  }
                  var products$4 = products$3._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deactivating",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var productIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (product) {
                                                          return Caml_obj.equal(id, product.id);
                                                        }), __x);
                                          })(products$4);
                                      if (match !== undefined && match.status === "Active") {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.deactivateProductsInBatch(productIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeactivateInBatch",
                                                    _0: productIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProducts",
                                            subModulePath: {
                                              hd: "DashboardProducts",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardProducts.DashboardProducts.make"
                                          }, "DashboardProducts" + "::DeactivateInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "ArchiveInBatch" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Archiving",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var productIds = Belt_Set.toArray(param.state.selection);
                              $$Promise.wait(Api.archiveProductsInBatch(productIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedArchiveInBatch",
                                                    _0: productIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProducts",
                                            subModulePath: {
                                              hd: "DashboardProducts",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardProducts.DashboardProducts.make"
                                          }, "DashboardProducts" + "::ArchiveInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "UnarchiveInBatch" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Unarchiving",
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var products = state.status;
                              if (typeof products !== "object") {
                                return ;
                              }
                              var products$1 = products._0;
                              var productIds = Belt_Array.keep(Belt_Set.toArray(state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (product) {
                                                          return Caml_obj.equal(product.id, id);
                                                        }), __x);
                                          })(products$1);
                                      if (match !== undefined && match.status === "Archived") {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.unarchiveProductsInBatch(productIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedUnarchiveInBatch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "DashboardProducts",
                                            subModulePath: {
                                              hd: "DashboardProducts",
                                              tl: /* [] */0
                                            },
                                            value: "make",
                                            fullPath: "DashboardProducts.DashboardProducts.make"
                                          }, "DashboardProducts" + "::AUnachiveInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatchAction");
                                    }));
                            })
                        };
              case "FailInBatchAction" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProductsFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.products
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$14 = state.status;
                  if (typeof match$14 !== "object" && match$14 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProducts",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProducts");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          },
                          _1: searchProducts
                        };
              case "PerformSearch" :
                  var match$15 = state.status;
                  if (typeof match$15 !== "object" && match$15 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingProducts",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProducts");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$16 = state.batchUpdate;
                  if (match$16 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false
                            }
                          };
                  }
              case "ToggleProviderFilter" :
                  var providerId = action._0;
                  var match$17 = state.status;
                  if (typeof match$17 !== "object" && match$17 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var providerFilter = Belt_Set.has(state.providerFilter, providerId) ? Belt_Set.remove(state.providerFilter, providerId) : Belt_Set.add(state.providerFilter, providerId);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "ToggleStatusFilter" :
                  var status = action._0;
                  var match$18 = state.status;
                  if (typeof match$18 !== "object" && match$18 === "FetchingProducts") {
                    return "NoUpdate";
                  }
                  var statusFilter = Belt_Set.has(state.statusFilter, status) ? Belt_Set.remove(state.statusFilter, status) : Belt_Set.add(state.statusFilter, status);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingProducts",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: ID.$$Set.make(),
                            providerFilter: state.providerFilter,
                            statusFilter: statusFilter,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProducts");
                            })
                        };
              case "UpdateActivityStatus" :
                  var action$1 = action._1;
                  var productId = action._0;
                  var match$19 = state.status;
                  var match$20 = state.batchUpdate;
                  var match$21 = Belt_Map.get(state.entityUpdates, productId);
                  if (typeof match$19 !== "object") {
                    match$19 === "FetchingProducts";
                  } else if (match$20 === undefined && match$21 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$19._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, productId)) {
                                          return x;
                                        }
                                        var newrecord = Caml_obj.obj_dup(x);
                                        var tmp;
                                        tmp = action$1 === "Activate" ? "Active" : "Disabled";
                                        newrecord.status = tmp;
                                        return newrecord;
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, productId, {
                                    TAG: "ActivityUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Activate") {
                                  return $$Promise.wait(Api.activateProduct(productId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActivityStatusUpdate",
                                                              _0: productId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "DashboardProducts",
                                                      subModulePath: {
                                                        hd: "DashboardProducts",
                                                        tl: /* [] */0
                                                      },
                                                      value: "make",
                                                      fullPath: "DashboardProducts.DashboardProducts.make"
                                                    }, "DashboardProducts" + "::UpdateStatus::Activate::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActivityStatusUpdate",
                                                      _0: productId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api.deactivateProduct(productId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedActivityStatusUpdate",
                                                              _0: productId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "DashboardProducts",
                                                      subModulePath: {
                                                        hd: "DashboardProducts",
                                                        tl: /* [] */0
                                                      },
                                                      value: "make",
                                                      fullPath: "DashboardProducts.DashboardProducts.make"
                                                    }, "DashboardProducts" + "::UpdateStatus::Disable::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailActivityStatusUpdate",
                                                      _0: productId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "FailActivityStatusUpdate" :
                  var productId$1 = action._0;
                  var match$22 = state.status;
                  var match$23 = Belt_Map.get(state.entityUpdates, productId$1);
                  if (typeof match$22 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$23 === undefined) {
                    return "NoUpdate";
                  }
                  switch (match$23.TAG) {
                    case "ActivityUpdate" :
                        var action$2 = match$23._0;
                        return {
                                TAG: "Update",
                                _0: {
                                  status: {
                                    TAG: "Ready",
                                    _0: Belt_Array.map(match$22._0, (function (x) {
                                            if (!Caml_obj.equal(x.id, productId$1)) {
                                              return x;
                                            }
                                            var newrecord = Caml_obj.obj_dup(x);
                                            var tmp;
                                            tmp = action$2 === "Activate" ? "Disabled" : "Active";
                                            newrecord.status = tmp;
                                            return newrecord;
                                          }))
                                  },
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  sortBy: state.sortBy,
                                  search: state.search,
                                  selection: state.selection,
                                  providerFilter: state.providerFilter,
                                  statusFilter: state.statusFilter,
                                  entityUpdates: Belt_Map.remove(state.entityUpdates, productId$1),
                                  batchUpdate: state.batchUpdate,
                                  batchUpdateError: state.batchUpdateError
                                }
                              };
                    case "ArchivingUpdate" :
                    case "EndDateUpdate" :
                        return "NoUpdate";
                    
                  }
              case "ExtendProduct" :
                  var days = action._1;
                  var id$1 = action._0;
                  var match$24 = state.status;
                  var match$25 = state.batchUpdate;
                  var match$26 = Belt_Map.get(state.entityUpdates, id$1);
                  if (typeof match$24 !== "object") {
                    match$24 === "FetchingProducts";
                  } else if (match$25 === undefined && match$26 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, id$1, {
                                    TAG: "EndDateUpdate",
                                    _0: days
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                $$Promise.wait(Api.extendProductEndDate(id$1, days), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return dispatch({
                                                      TAG: "SucceedProductExtend",
                                                      _0: x._0
                                                    });
                                        }
                                        SentryLogger.error1({
                                              rootModule: "DashboardProducts",
                                              subModulePath: {
                                                hd: "DashboardProducts",
                                                tl: /* [] */0
                                              },
                                              value: "make",
                                              fullPath: "DashboardProducts.DashboardProducts.make"
                                            }, "DashboardProducts" + "::ExtendProduct::Error", [
                                              "Error",
                                              x._0
                                            ]);
                                        dispatch({
                                              TAG: "FailProductExtend",
                                              _0: id$1
                                            });
                                      }));
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedProductExtend" :
                  var product = action._0;
                  var match$27 = state.status;
                  var match$28 = Belt_Map.get(state.entityUpdates, product.id);
                  if (typeof match$27 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$28 === undefined) {
                    return "NoUpdate";
                  }
                  switch (match$28.TAG) {
                    case "ActivityUpdate" :
                    case "ArchivingUpdate" :
                        return "NoUpdate";
                    case "EndDateUpdate" :
                        return {
                                TAG: "Update",
                                _0: {
                                  status: {
                                    TAG: "Ready",
                                    _0: Belt_Array.map(match$27._0, (function (x) {
                                            if (Caml_obj.equal(x.id, product.id)) {
                                              return product;
                                            } else {
                                              return x;
                                            }
                                          }))
                                  },
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  sortBy: state.sortBy,
                                  search: state.search,
                                  selection: state.selection,
                                  providerFilter: state.providerFilter,
                                  statusFilter: state.statusFilter,
                                  entityUpdates: Belt_Map.remove(state.entityUpdates, product.id),
                                  batchUpdate: state.batchUpdate,
                                  batchUpdateError: state.batchUpdateError
                                }
                              };
                    
                  }
              case "FailProductExtend" :
                  var id$2 = action._0;
                  var match$29 = state.status;
                  var match$30 = Belt_Map.get(state.entityUpdates, id$2);
                  if (typeof match$29 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$30 === undefined) {
                    return "NoUpdate";
                  }
                  switch (match$30.TAG) {
                    case "ActivityUpdate" :
                    case "ArchivingUpdate" :
                        return "NoUpdate";
                    case "EndDateUpdate" :
                        return {
                                TAG: "Update",
                                _0: {
                                  status: state.status,
                                  currentPage: state.currentPage,
                                  totalPages: state.totalPages,
                                  sortBy: state.sortBy,
                                  search: state.search,
                                  selection: state.selection,
                                  providerFilter: state.providerFilter,
                                  statusFilter: state.statusFilter,
                                  entityUpdates: Belt_Map.remove(state.entityUpdates, id$2),
                                  batchUpdate: state.batchUpdate,
                                  batchUpdateError: state.batchUpdateError
                                }
                              };
                    
                  }
              case "ArchiveProduct" :
                  var productId$2 = action._0;
                  var match$31 = state.status;
                  var match$32 = state.batchUpdate;
                  var match$33 = Belt_Map.get(state.entityUpdates, productId$2);
                  if (typeof match$31 !== "object") {
                    match$31 === "FetchingProducts";
                  } else if (match$32 === undefined && match$33 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, productId$2, {
                                    TAG: "ArchivingUpdate",
                                    _0: "Archive"
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                $$Promise.wait(Api.archiveProduct(productId$2), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return dispatch({
                                                      TAG: "SucceedProductArchive",
                                                      _0: productId$2
                                                    });
                                        }
                                        SentryLogger.error1({
                                              rootModule: "DashboardProducts",
                                              subModulePath: {
                                                hd: "DashboardProducts",
                                                tl: /* [] */0
                                              },
                                              value: "make",
                                              fullPath: "DashboardProducts.DashboardProducts.make"
                                            }, "DashboardProducts" + "::ArchiveProduct::Error", [
                                              "Error",
                                              x._0
                                            ]);
                                        dispatch({
                                              TAG: "FailProductArchive",
                                              _0: productId$2
                                            });
                                      }));
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedProductArchive" :
                  var productId$3 = action._0;
                  var products$5 = state.status;
                  if (typeof products$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$5._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, productId$3)) {
                                          return x;
                                        }
                                        var newrecord = Caml_obj.obj_dup(x);
                                        newrecord.status = "Archived";
                                        return newrecord;
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.remove(state.entityUpdates, productId$3),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            }
                          };
                  }
              case "UnarchiveProduct" :
                  var productId$4 = action._0;
                  var match$34 = state.status;
                  var match$35 = state.batchUpdate;
                  var match$36 = Belt_Map.get(state.entityUpdates, productId$4);
                  if (typeof match$34 !== "object") {
                    match$34 === "FetchingProducts";
                  } else if (match$35 === undefined && match$36 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.set(state.entityUpdates, productId$4, {
                                    TAG: "ArchivingUpdate",
                                    _0: "Unarchive"
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                $$Promise.wait(Api.unarchiveProduct(productId$4), (function (x) {
                                        if (x.TAG === "Ok") {
                                          return dispatch({
                                                      TAG: "SucceedProductUnarchive",
                                                      _0: x._0
                                                    });
                                        }
                                        SentryLogger.error1({
                                              rootModule: "DashboardProducts",
                                              subModulePath: {
                                                hd: "DashboardProducts",
                                                tl: /* [] */0
                                              },
                                              value: "make",
                                              fullPath: "DashboardProducts.DashboardProducts.make"
                                            }, "DashboardProducts" + "::UnarchiveProduct::Error", [
                                              "Error",
                                              x._0
                                            ]);
                                        dispatch({
                                              TAG: "FailProductUnarchive",
                                              _0: productId$4
                                            });
                                      }));
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedProductUnarchive" :
                  var product$1 = action._0;
                  var products$6 = state.status;
                  if (typeof products$6 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$6._0, (function (x) {
                                        if (Caml_obj.equal(x.id, product$1.id)) {
                                          return product$1;
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: state.selection,
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: Belt_Map.remove(state.entityUpdates, product$1.id),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError
                            }
                          };
                  }
              case "SucceedActivityStatusUpdate" :
              case "FailProductArchive" :
              case "FailProductUnarchive" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            selection: state.selection,
                            providerFilter: state.providerFilter,
                            statusFilter: state.statusFilter,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError
                          }
                        };
              case "SucceedActivateInBatch" :
                  var ids = action._0;
                  var products$7 = state.status;
                  if (typeof products$7 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$7._0, (function (x) {
                                        var id = x.id;
                                        if (!(function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids)) {
                                          return x;
                                        }
                                        var newrecord = Caml_obj.obj_dup(x);
                                        newrecord.status = "Active";
                                        return newrecord;
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              case "SucceedDeactivateInBatch" :
                  var ids$1 = action._0;
                  var products$8 = state.status;
                  if (typeof products$8 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$8._0, (function (x) {
                                        var id = x.id;
                                        if (!(function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$1)) {
                                          return x;
                                        }
                                        var newrecord = Caml_obj.obj_dup(x);
                                        newrecord.status = "Disabled";
                                        return newrecord;
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              case "SucceedArchiveInBatch" :
                  var ids$2 = action._0;
                  var products$9 = state.status;
                  if (typeof products$9 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$9._0, (function (x) {
                                        var id = x.id;
                                        if (!(function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(ids$2)) {
                                          return x;
                                        }
                                        var newrecord = Caml_obj.obj_dup(x);
                                        newrecord.status = "Archived";
                                        return newrecord;
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              case "SucceedUnarchiveInBatch" :
                  var updatedProducts = action._0;
                  var products$10 = state.status;
                  if (typeof products$10 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(products$10._0, (function (product) {
                                        return Belt_Option.getWithDefault((function (__x) {
                                                        return Js_array.find((function (product$p) {
                                                                      return Caml_obj.equal(product$p.id, product.id);
                                                                    }), __x);
                                                      })(updatedProducts), product);
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              providerFilter: state.providerFilter,
                              statusFilter: state.statusFilter,
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false
                            }
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProducts");
        }), []);
  var tmp;
  switch (props.userRole) {
    case "Admin" :
        tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
              selectedCategory: "Products",
              isAdmin: true,
              isConcierge: isConcierge,
              isNotOnlyProviderMember: isNotOnlyProviderMember
            });
        break;
    case "Provider" :
        tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
              selectedCategory: "Products",
              isAdmin: false,
              isConcierge: isConcierge,
              isNotOnlyProviderMember: isNotOnlyProviderMember
            });
        break;
    default:
      tmp = null;
  }
  var len = providers.length;
  var tmp$1;
  if (len !== 1 && len !== 0) {
    var x = Belt_Set.size(state.providerFilter);
    tmp$1 = JsxRuntime.jsxs(Dropdown.make, {
          children: [
            JsxRuntime.jsx(Dropdown.Trigger.make, {
                  className: css.dropdown,
                  children: x !== 0 ? (
                      x !== 1 ? String(x) + " providers" : "1 provider"
                    ) : "All providers"
                }),
            JsxRuntime.jsx(Dropdown.Body.make, {
                  position: {
                    TAG: "Below",
                    _0: "LeftEdge"
                  },
                  className: css.dropdownBody,
                  children: Belt_Array.map(providers, (function (provider) {
                          var id = "product-providers-filter--provider-" + ID.toString(provider.id);
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Checkbox.make, {
                                              id: id,
                                              size: "SM",
                                              checked: Belt_Set.has(state.providerFilter, provider.id),
                                              onChange: (function (param) {
                                                  dispatch({
                                                        TAG: "ToggleProviderFilter",
                                                        _0: provider.id
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx("label", {
                                              children: provider.name,
                                              className: css.label,
                                              htmlFor: id
                                            })
                                      ],
                                      className: css.dropdownBodyRow
                                    }, id);
                        }))
                })
          ]
        });
  } else {
    tmp$1 = null;
  }
  var x$1 = Belt_Set.size(state.statusFilter);
  var products = state.status;
  var tmp$2;
  if (typeof products !== "object") {
    tmp$2 = false;
  } else {
    var products$1 = products._0;
    tmp$2 = Belt_Set.size(state.selection) === products$1.length && products$1.length !== 0;
  }
  var match$1 = state.sortBy;
  var len$1 = providers.length;
  var tmp$3;
  if (len$1 !== 1 && len$1 !== 0) {
    var match$2 = state.sortBy;
    tmp$3 = JsxRuntime.jsx(Control.make, {
          className: css.controlRightAlign,
          onClick: (function (param) {
              dispatch("SortByProviderName");
            }),
          children: JsxRuntime.jsxs("div", {
                children: [
                  "Provider",
                  JsxRuntime.jsx(IconSorting.make, {
                        size: "XXS",
                        direction: typeof match$2 === "object" && match$2.NAME === "ProviderName" ? match$2.VAL : undefined
                      })
                ],
                className: Cx.cx([
                      css.provider,
                      css.tableHeaderCell
                    ])
              })
        });
  } else {
    tmp$3 = null;
  }
  var match$3 = state.sortBy;
  var match$4 = state.sortBy;
  var match$5 = state.sortBy;
  var len$2 = providers.length;
  var products$2 = state.status;
  var tmp$4;
  tmp$4 = typeof products$2 !== "object" ? (
      products$2 === "FetchingProducts" ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: Cx.cx([
                    css.tableBody,
                    css.tableBodyMinHeight
                  ])
            }) : JsxRuntime.jsx("div", {
              children: "Something went wrong during the search",
              className: Cx.cx([
                    css.tableBody,
                    css.tableBodyMinHeight,
                    css.fetchError
                  ])
            })
    ) : JsxRuntime.jsx("div", {
          children: Belt_Array.map(products$2._0, (function (product) {
                  var len = providers.length;
                  var match = product.status;
                  var tmp;
                  var exit = 0;
                  switch (match) {
                    case "Draft" :
                        tmp = JsxRuntime.jsx(DashboardProductStatusBadge.make, {
                              status: "Draft"
                            });
                        break;
                    case "Active" :
                    case "Disabled" :
                        exit = 1;
                        break;
                    case "Expired" :
                        tmp = JsxRuntime.jsx(DashboardProductStatusBadge.make, {
                              status: "Expired"
                            });
                        break;
                    case "Archived" :
                        tmp = JsxRuntime.jsx(DashboardProductStatusBadge.make, {
                              status: "Archived"
                            });
                        break;
                    
                  }
                  if (exit === 1) {
                    var active = product.status === "Active";
                    tmp = JsxRuntime.jsx(Toggle.make, {
                          on: active,
                          size: "MD",
                          onChange: (function () {
                              dispatch({
                                    TAG: "UpdateActivityStatus",
                                    _0: product.id,
                                    _1: active ? "Disable" : "Activate"
                                  });
                            })
                        });
                  }
                  var match$1 = product.status;
                  var tmp$1;
                  var exit$1 = 0;
                  switch (match$1) {
                    case "Draft" :
                    case "Disabled" :
                        exit$1 = 2;
                        break;
                    case "Active" :
                    case "Expired" :
                        exit$1 = 1;
                        break;
                    case "Archived" :
                        var match$2 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$2;
                        if (match$2 !== undefined) {
                          switch (match$2.TAG) {
                            case "ArchivingUpdate" :
                                tmp$2 = match$2._0 === "Archive" ? "Unarchive" : "Unarchiving...";
                                break;
                            case "ActivityUpdate" :
                            case "EndDateUpdate" :
                                tmp$2 = "Unarchive";
                                break;
                            
                          }
                        } else {
                          tmp$2 = "Unarchive";
                        }
                        tmp$1 = JsxRuntime.jsx(Control.make, {
                              className: css.dropdownBodyRow,
                              onClick: (function (param) {
                                  dispatch({
                                        TAG: "UnarchiveProduct",
                                        _0: product.id
                                      });
                                }),
                              children: tmp$2
                            });
                        break;
                    
                  }
                  switch (exit$1) {
                    case 1 :
                        var match$3 = product.status;
                        var tmp$3;
                        tmp$3 = match$3 === "Active" ? JsxRuntime.jsx(Control.make, {
                                className: css.dropdownBodyRow,
                                children: JsxRuntime.jsx(Link.make, {
                                      href: product.url,
                                      className: css.dropdownBodyLink,
                                      children: "View live"
                                    })
                              }) : null;
                        var match$4 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$4;
                        if (match$4 !== undefined) {
                          switch (match$4.TAG) {
                            case "ActivityUpdate" :
                            case "ArchivingUpdate" :
                                tmp$4 = "Extend 30 days";
                                break;
                            case "EndDateUpdate" :
                                tmp$4 = match$4._0 !== 30 ? "Extend 30 days" : "Extending...";
                                break;
                            
                          }
                        } else {
                          tmp$4 = "Extend 30 days";
                        }
                        var match$5 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$5;
                        if (match$5 !== undefined) {
                          switch (match$5.TAG) {
                            case "ActivityUpdate" :
                            case "ArchivingUpdate" :
                                tmp$5 = "Extend 60 days";
                                break;
                            case "EndDateUpdate" :
                                tmp$5 = match$5._0 !== 60 ? "Extend 60 days" : "Extending...";
                                break;
                            
                          }
                        } else {
                          tmp$5 = "Extend 60 days";
                        }
                        var match$6 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$6;
                        if (match$6 !== undefined) {
                          switch (match$6.TAG) {
                            case "ActivityUpdate" :
                            case "ArchivingUpdate" :
                                tmp$6 = "Extend 90 days";
                                break;
                            case "EndDateUpdate" :
                                tmp$6 = match$6._0 !== 90 ? "Extend 90 days" : "Extending...";
                                break;
                            
                          }
                        } else {
                          tmp$6 = "Extend 90 days";
                        }
                        var match$7 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$7;
                        if (match$7 !== undefined) {
                          switch (match$7.TAG) {
                            case "ArchivingUpdate" :
                                tmp$7 = match$7._0 === "Archive" ? "Archiving..." : "Archive";
                                break;
                            case "ActivityUpdate" :
                            case "EndDateUpdate" :
                                tmp$7 = "Archive";
                                break;
                            
                          }
                        } else {
                          tmp$7 = "Archive";
                        }
                        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Product.Dashboard.editGeneral(product.id),
                                            className: css.dropdownBodyLink,
                                            children: "Edit"
                                          })
                                    }),
                                tmp$3,
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "ExtendProduct",
                                                _0: product.id,
                                                _1: 30
                                              });
                                        }),
                                      children: tmp$4
                                    }),
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "ExtendProduct",
                                                _0: product.id,
                                                _1: 60
                                              });
                                        }),
                                      children: tmp$5
                                    }),
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "ExtendProduct",
                                                _0: product.id,
                                                _1: 90
                                              });
                                        }),
                                      children: tmp$6
                                    }),
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "ArchiveProduct",
                                                _0: product.id
                                              });
                                        }),
                                      children: tmp$7
                                    })
                              ]
                            });
                        break;
                    case 2 :
                        var match$8 = Belt_Map.get(state.entityUpdates, product.id);
                        var tmp$8;
                        if (match$8 !== undefined) {
                          switch (match$8.TAG) {
                            case "ArchivingUpdate" :
                                tmp$8 = match$8._0 === "Archive" ? "Archiving..." : "Archive";
                                break;
                            case "ActivityUpdate" :
                            case "EndDateUpdate" :
                                tmp$8 = "Archive";
                                break;
                            
                          }
                        } else {
                          tmp$8 = "Archive";
                        }
                        tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Product.Dashboard.editGeneral(product.id),
                                            className: css.dropdownBodyLink,
                                            children: "Edit"
                                          })
                                    }),
                                JsxRuntime.jsx(Control.make, {
                                      className: css.dropdownBodyRow,
                                      onClick: (function (param) {
                                          dispatch({
                                                TAG: "ArchiveProduct",
                                                _0: product.id
                                              });
                                        }),
                                      children: tmp$8
                                    })
                              ]
                            });
                        break;
                    
                  }
                  var len$1 = providers.length;
                  return JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Checkbox.make, {
                                            id: "product--" + (ID.toString(product.id) + "--selected"),
                                            size: "SM",
                                            checked: Belt_Set.has(state.selection, product.id),
                                            onChange: (function (param) {
                                                dispatch({
                                                      TAG: "ToggleSelectionOfItem",
                                                      _0: product.id
                                                    });
                                              })
                                          }),
                                      className: css.selected
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Link.make, {
                                            href: Routes_Product.Dashboard.editGeneral(product.id),
                                            children: product.name
                                          }),
                                      className: css.name
                                    }),
                                len !== 1 && len !== 0 ? JsxRuntime.jsx("div", {
                                        children: product.providerName,
                                        className: css.provider
                                      }) : null,
                                JsxRuntime.jsx("div", {
                                      children: Money.formatWithCents(product.pricePerMonth),
                                      className: css.price
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: $$Date.Naive.format(product.startDate, "MM/dd/yyyy"),
                                      className: css.startDate
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: $$Date.Naive.format(product.endDate, "MM/dd/yyyy"),
                                      className: css.endDate
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: tmp,
                                      className: css.status
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsx(Popover.Trigger.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          children: JsxRuntime.jsx(IconMenu.make, {
                                                                size: "MD",
                                                                color: "Teal"
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "RightEdge"
                                                    },
                                                    className: css.dropdownBody,
                                                    children: tmp$1
                                                  })
                                            ]
                                          }),
                                      className: css.productMenu
                                    })
                              ],
                              className: Cx.cx([
                                    css.row,
                                    len$1 !== 1 && len$1 !== 0 ? css.withProvider : css.withoutProvider
                                  ])
                            }, ID.toString(product.id));
                })),
          className: css.tableBody
        });
  var products$3 = state.status;
  var tmp$5;
  if (typeof products$3 !== "object") {
    tmp$5 = null;
  } else {
    var products$4 = products$3._0;
    tmp$5 = JsxRuntime.jsx(DashboardProductsActionBar.make, {
          selection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (product) {
                                                    return Caml_obj.equal(id, product.id);
                                                  }), __x);
                                    })(products$4), (function (product) {
                                    return product.status;
                                  }));
                    })), [], (function (acc, status) {
                  if (status !== undefined) {
                    return Belt_Array.concat(acc, [status]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          activate: (function (param) {
              dispatch("ActivateInBatch");
            }),
          deactivate: (function (param) {
              dispatch("DeactivateInBatch");
            }),
          archive: (function (param) {
              dispatch("ArchiveInBatch");
            }),
          unarchive: (function (param) {
              dispatch("UnarchiveInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Products"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "products-search",
                                              value: state.search,
                                              inputClassName: css.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        tmp$1,
                                        JsxRuntime.jsxs(Dropdown.make, {
                                              children: [
                                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                                      className: css.dropdown,
                                                      children: x$1 !== 0 ? (
                                                          x$1 !== 1 ? String(x$1) + " statuses" : "1 status"
                                                        ) : "All statuses"
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "LeftEdge"
                                                      },
                                                      className: css.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "product-status-filter--active",
                                                                      size: "SM",
                                                                      checked: Belt_Set.has(state.statusFilter, "Active"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleStatusFilter",
                                                                                _0: "Active"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Active",
                                                                      className: css.label,
                                                                      htmlFor: "product-status-filter--active"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "product-status-filter--draft",
                                                                      size: "SM",
                                                                      checked: Belt_Set.has(state.statusFilter, "Draft"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleStatusFilter",
                                                                                _0: "Draft"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Draft",
                                                                      className: css.label,
                                                                      htmlFor: "product-status-filter--draft"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "product-status-filter--disabled",
                                                                      size: "SM",
                                                                      checked: Belt_Set.has(state.statusFilter, "Disabled"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleStatusFilter",
                                                                                _0: "Disabled"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Disabled",
                                                                      className: css.label,
                                                                      htmlFor: "product-status-filter--disabled"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "product-status-filter--expired",
                                                                      size: "SM",
                                                                      checked: Belt_Set.has(state.statusFilter, "Expired"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleStatusFilter",
                                                                                _0: "Expired"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Expired",
                                                                      className: css.label,
                                                                      htmlFor: "product-status-filter--expired"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsx(Checkbox.make, {
                                                                      id: "product-status-filter--archived",
                                                                      size: "SM",
                                                                      checked: Belt_Set.has(state.statusFilter, "Archived"),
                                                                      onChange: (function (param) {
                                                                          dispatch({
                                                                                TAG: "ToggleStatusFilter",
                                                                                _0: "Archived"
                                                                              });
                                                                        })
                                                                    }),
                                                                JsxRuntime.jsx("label", {
                                                                      children: "Archived",
                                                                      className: css.label,
                                                                      htmlFor: "product-status-filter--archived"
                                                                    })
                                                              ],
                                                              className: css.dropdownBodyRow
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(Button.AsLink.make, {
                                              href: Routes_Product.Dashboard.$$new,
                                              size: "SM",
                                              color: "Primary",
                                              className: css.addNewProductButton,
                                              children: "Add New Product"
                                            })
                                      ],
                                      className: css.headerControls
                                    })
                              ],
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Checkbox.make, {
                                                    id: "product--all--selected",
                                                    size: "SM",
                                                    checked: tmp$2,
                                                    onChange: (function (param) {
                                                        dispatch("ToggleSelectionOfAllItems");
                                                      })
                                                  }),
                                              className: css.selected
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              onClick: (function (param) {
                                                  dispatch("SortByName");
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Product",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$1 === "object" && match$1.NAME === "Name" ? match$1.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.name,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        tmp$3,
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlRightAlign,
                                              onClick: (function (param) {
                                                  dispatch("SortByPrice");
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Price",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$3 === "object" && match$3.NAME === "Price" ? match$3.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.price,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlRightAlign,
                                              onClick: (function (param) {
                                                  dispatch("SortByStartDate");
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "Start date",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$4 === "object" && match$4.NAME === "StartDate" ? match$4.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.startDate,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx(Control.make, {
                                              className: css.controlRightAlign,
                                              onClick: (function (param) {
                                                  dispatch("SortByEndDate");
                                                }),
                                              children: JsxRuntime.jsxs("div", {
                                                    children: [
                                                      "End date",
                                                      JsxRuntime.jsx(IconSorting.make, {
                                                            size: "XXS",
                                                            direction: typeof match$5 === "object" && match$5.NAME === "EndDate" ? match$5.VAL : undefined
                                                          })
                                                    ],
                                                    className: Cx.cx([
                                                          css.endDate,
                                                          css.tableHeaderCell
                                                        ])
                                                  })
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Status",
                                              className: Cx.cx([
                                                    css.status,
                                                    css.tableHeaderCell
                                                  ])
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.row,
                                            len$2 !== 1 && len$2 !== 0 ? css.withProvider : css.withoutProvider,
                                            css.tableHeader
                                          ])
                                    }),
                                tmp$4
                              ],
                              className: css.table
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: css.pagination
                              }) : null
                      ]
                    }),
                tmp$5
              ],
              className: css.pageContainer
            });
}

var DashboardProducts = {
  css: css,
  Product: undefined,
  ProductStatusId: ProductStatusId,
  initialState: initialState,
  make: DashboardProducts$DashboardProducts
};

function DashboardProducts$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(DashboardProducts$DashboardProducts, {
              providers: Belt_SortArray.stableSortBy(Belt_Array.map(props$1.providers, ProviderAsFilter.fromJs), (function (x1, x2) {
                      return Caml.string_compare(x1.name, x2.name);
                    })),
              isConcierge: props$1.isConcierge,
              isNotOnlyProviderMember: props$1.isNotOnlyProviderMember,
              userRole: Role.fromString(props.context.userRole)
            });
}

var $$default = DashboardProducts$default;

export {
  DashboardProducts ,
  $$default as default,
}
/* css Not a pure module */
