// Generated by ReScript, PLEASE EDIT WITH CARE


var Dashboard = {
  index: "/dashboard/orders"
};

export {
  Dashboard ,
}
/* No side effect */
