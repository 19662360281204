// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Order from "../../../../routes/common/Routes_Order.res.js";
import * as Routes_Product from "../../../../routes/common/Routes_Product.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AdminTopNavbarScss from "./AdminTopNavbar.scss";

var css = AdminTopNavbarScss;

function AdminTopNavbar(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var selectedCategory = props.selectedCategory;
  var tmp;
  var exit = 0;
  if (props.isAdmin) {
    exit = 1;
  } else if (props.isConcierge) {
    if (isNotOnlyProviderMember) {
      exit = 1;
    } else {
      tmp = [
        {
          id: "Projects",
          slug: Routes_Project.Dashboard.index
        },
        {
          id: "ConciergeProjects",
          slug: Routes_Project.Dashboard.conciergeIndex
        }
      ];
    }
  } else {
    tmp = isNotOnlyProviderMember ? [
        {
          id: "Profiles",
          slug: Routes_Provider.Dashboard.index
        },
        {
          id: "Projects",
          slug: Routes_Project.Dashboard.index
        },
        {
          id: "Products",
          slug: Routes_Product.Dashboard.index
        },
        {
          id: "Orders",
          slug: Routes_Order.Dashboard.index
        }
      ] : [{
          id: "Projects",
          slug: Routes_Project.Dashboard.index
        }];
  }
  if (exit === 1) {
    tmp = [
      {
        id: "Profiles",
        slug: Routes_Provider.Dashboard.index
      },
      {
        id: "Projects",
        slug: Routes_Project.Dashboard.index
      },
      {
        id: "ConciergeProjects",
        slug: Routes_Project.Dashboard.conciergeIndex
      },
      {
        id: "Products",
        slug: Routes_Product.Dashboard.index
      },
      {
        id: "Orders",
        slug: Routes_Order.Dashboard.index
      }
    ];
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex(tmp, (function (index, category) {
                            var match = category.id;
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: category.slug,
                                              className: css.linkItem,
                                              children: match === "Orders" ? "Marketplace Orders" : (
                                                  match === "ConciergeProjects" ? "Concierge Projects" : (
                                                      match === "Projects" ? "Provider Projects" : (
                                                          match === "Products" ? "Marketplace Products" : "Profiles \u0026 Listings"
                                                        )
                                                    )
                                                )
                                            }),
                                        className: Cx.cx([
                                              css.linkContainer,
                                              category.id === selectedCategory ? css.underlinedLink : ""
                                            ])
                                      }, String(index));
                          })),
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = AdminTopNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
