// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductsScss from "./DashboardProducts.scss";

var css = DashboardProductsScss;

function DashboardProductStatusBadge(props) {
  switch (props.status) {
    case "Draft" :
        return JsxRuntime.jsx("div", {
                    children: "Draft",
                    className: Cx.cx([
                          css.badge,
                          css.draftBadge
                        ])
                  });
    case "Expired" :
        return JsxRuntime.jsx("div", {
                    children: "Expired",
                    className: Cx.cx([
                          css.badge,
                          css.expiredBadge
                        ])
                  });
    case "Archived" :
        return JsxRuntime.jsx("div", {
                    children: "Archived",
                    className: Cx.cx([
                          css.badge,
                          css.archivedBadge
                        ])
                  });
    
  }
}

var make = DashboardProductStatusBadge;

export {
  css ,
  make ,
}
/* css Not a pure module */
